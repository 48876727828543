import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
// import VueApollo from 'vue-apollo'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import { ApolloClient } from 'apollo-client'
// import { split } from 'apollo-link'
// import { HttpLink } from 'apollo-link-http'
// import { WebSocketLink } from 'apollo-link-ws'
// import { InMemoryCache } from 'apollo-cache-inmemory'
// import { getMainDefinition } from 'apollo-utilities'

Vue.config.productionTip = false
// Vue.use(VueApollo)
Vue.use(VueAxios, axios)
// var httpsHasura = 'https://hasura-dev.news.chat.korkla.in/v1/graphql'
// var wssHasura = 'wss://hasura-dev.news.chat.korkla.in/v1/graphql'
// var secretHasura = 'one@chat@news@j9EZJmM'

// const httpLink = new HttpLink({
//   uri: process.env.VUE_APP_hasura_https,
//   fetch,
//   headers: {
//     'x-hasura-admin-secret': process.env.VUE_APP_secrete,
//   },
// })
//
// const wsLink = new WebSocketLink({
//   // You should use an absolute URL here
//   uri: process.env.VUE_APP_hasura_wss,
//   options: {
//     reconnect: true,
//     timeout: 30000,
//     connectionParams: () => {
//       return {
//         headers: {
//           'x-hasura-admin-secret': process.env.VUE_APP_secrete,
//         },
//       }
//     },
//   },
// })

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
//   },
//   wsLink,
//   httpLink,
// )

// Create the apollo client
// const client = new ApolloClient({
//   link: link,
//   cache: new InMemoryCache({
//     addTypename: true,
//   }),
// })
//
// const apolloProvider = new VueApollo({
//   defaultClient: client,
// })

sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  // apolloProvider,
  render: h => h(App),
}).$mount('#app')
